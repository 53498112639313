<!-- =========================================================================================
  File Name: appro_depense view
  Description: view of approvisionnement caisse depense
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author Email: eudoxiedjetouan@gmail.com
========================================================================================== -->


<template>
  <div id="page-personnel-edit">
    <versement-banq-list-tab></versement-banq-list-tab>
  </div>
</template>

<script>
//import VersementBanqAddTab from './appro_depense_add.vue'
import VersementBanqListTab from './appro_depense_list.vue'
export default {
  name: 'VersementBanqueView',
  components: {
    // VersementBanqAddTab,
    VersementBanqListTab
  },
  data () {
    return {
      activeTab: 0
    }
  }
}
</script>

<style scoped>

</style>
